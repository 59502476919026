$primary: #000; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Bangers');
//font-family: 'Bangers', cursive;
@import url('https://fonts.googleapis.com/css?family=Poppins');
//font-family: 'Poppins', sans-serif;

html,body {
  height: 100%;
}

h2, h3{
	font-family: 'Bangers', cursive;
}

p{
 	font-family: 'Poppins', sans-serif;
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

.navbarFixed {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 999;
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;
	background-color: #fff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

	.navbar-nav {

	    > li > a {
	    	border-radius: $border-radius;
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;

			@media (max-width: 767px) {
				  margin-top: 0;
				  padding: 6px 0px;
			    display: inline-block;
			}
			
			@media(max-width: 568px) and (orientation: landscape) {
				margin-top: -4px;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
	}
}

.navbar-right {
	margin-top: 12px;
}

.navbar-toggle {
    margin: 26px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 600px) {
    	margin-top: 26px;
  	}
}

/** LOGIN FORM **/
@mixin btn {
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 2em 0em;
	transition: 0.7s ease all;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		text-decoration: none;
		
		@media (max-width: 1024px) {
			background: $secondary;
			color: $wht;
			box-shadow: 0px 0px 5px $blk;
		}
	}
}

.btn-default {
	@include btn;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

    input#username, input#password {
  		@media (max-width: 1024px) {
   			font-size: 16px;
  		}
 	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn;
		display: block;
		width: 50%;
		font-size: 18px;
    	margin: 0 auto;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/
footer {
	padding: 40px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;

	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;
		
		@media (max-width: 990px) {
			font-size: 13px;
		}
		
		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}

input#username, input#password {
	width: 100%;

	@media (max-width: 1024px) {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,a:hover,a:focus,a:active {
  outline: 0 !important;
}

.logo {
	max-width: 285px;
	// padding: 0.5em;

	@media (max-width: 767px){
		max-width: 130px;
	}

	@media (max-width: 600px){
		max-width: 125px;
	}

  	@media(max-width: 568px) and (orientation: landscape) {
    	max-width: 120px;
    	padding: 0em;
  	}
}

@media(max-width: 767px) {
    .navbar-header a {
        float: left;
    }
}

.top-pad {
	padding: 3em;
	background: white;
}

.flex {
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 1024px) {
		display: block;  /* may need to be inline-block */
	}
}

.btn {
	background-color: $primary;
	color:#fff;
	padding: 1em 1.5em; 
	
	&:hover{
		background-color: $blk;
		color: $wht;
	} 
}


.intro {
	background-image: url(../img/banner.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;	
	padding: 280px 0px;

	@media (max-width: 767px) {
		background-position: 50% 50%;
		padding: 120px 0px;	
	}

	h1{
		font-family: 'Bangers', cursive;
		color:#fff;
		text-shadow: 3px 3px 8px #000;
		font-size: 40px;
	}
}

.divider1 {
	background-image: url(../img/divider1.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;	
	padding: 280px 0px;

	@media (max-width: 767px) {
		background-position: 73% 50%;	
		padding: 125px 0px;
	}

	@media (max-width: 320px) {
		background-position: 66% 50%;	
	}

	h1{
		font-family: 'Bangers', cursive;
		color:#fff;
		text-shadow: 3px 3px 8px #000;
		font-size: 40px;
	}

}

.form {
	background-image: url(../img/formbg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;	
	padding: 280px 0px;

	@media (max-width: 767px) {
		padding: 10px 0px;
	}


	h1{
		font-family: 'Bangers', cursive;
		color:#fff;
		text-shadow: 3px 3px 8px #000;
		font-size: 40px;
	}
}

.divider2 {
	background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/divider2.jpg);
	// background-image: url(../img/divider2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;	
	padding: 250px 0px;

	@media (max-width: 767px){
		padding: 50px 0px;
	}

	h1{
		font-family: 'Bangers', cursive;
		color:#fff;
		text-shadow: 3px 3px 8px #000;
		font-size: 40px;
	}

	p{
		font-family: 'Poppins', sans-serif;
		color: #fff;
		font-size: 20px;
		text-shadow: 2px 2px 1px #000;

		@media (max-width: 767px) {
			font-size: 13px;
		}
	}
}

.outro {
	background-image: url(../img/banner2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 0%;	
	padding: 365px 0px;

	@media (max-width: 767px) {
		background-position: 60% 50%;
		padding: 100px 0px;
	}

	@media (max-width: 320px) {
		background-position: 55% 50%;	
	}

	h1{
		font-family: 'Bangers', cursive;
		color:#fff;
		text-shadow: 3px 3px 8px #000;
		font-size: 40px;
	}

}